import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import BajaAltaMultiple from "../../BajaAltaMultiple";
import StatusMultiple from "../../StatusMultiple";
import { TableHeader, Pagination, Search } from "../../../DataTable"
import useFullPageLoader from "../../../hooks/useFullPageLoader"
import moment from 'moment';
import { v4 as uuidv4 } from "uuid";

function ListadoOrdenesCompra() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_TIPOS_TELAS = process.env.REACT_APP_URL_TIPOS_TELAS;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_ORDENES_COMPRA = process.env.REACT_APP_URL_ORDENES_COMPRA;
  const URL_ARTICULOS_OC = process.env.REACT_APP_URL_ARTICULOS_OC;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;

  let hoy = new Date()
  let inicio = new Date()
  inicio.setDate(inicio.getDate()-30);
 
  let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  let startDate   = moment(inicio).format("YYYY-MM-DD");

  const [ordenesCompra, setOrdenesCompra] = useState([]);

  const [articulos, setArticulos] = useState([]);
  const [artOC, setArtOC] = useState([]);
  const [selectedTiposTelasFiltro, setSelectedTiposTelasFiltro] = useState("");
  const [colores, setColores] = useState([]);
  const [selectedColor, setSelectedColor] = useState("");

  const [proveedores, setProveedores] = useState([]);
  const [selectedProveedor, setSelectedProveedor] = useState("");
  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);
  const [selectedFechaCompInicio, setSelectedFechaCompInicio] = useState("");
  const [selectedFechaCompFin, setSelectedFechaCompFin] = useState("");

  const [fecha, setFecha] = useState("");
  const [plazo, setPlazo] = useState("");
  const [fecha_compromiso, setFechaCompromiso] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [total_general, setTotalGeneral] = useState("");
  const [total_kilos, setTotalUnidaKilos] = useState("");
  const [total_metros, setTotalUnidaMetros] = useState("");
  const [numero_orden, setNumeroOrden] = useState("");

  const [idEdit, setIdEdit] = useState("");
  const [selectedProveedorEdit, setSelectedProveedorEdit] = useState("");

  const [articulosOrden, setArticulosOrden] = useState([]);
  const [modalEditArticulos, setModalEditArticulos] = useState(false);
  const toggleEditArticulos = () => setModalEditArticulos(!modalEditArticulos);
  const [total_general_edit, setTotalGeneralEdit] = useState(0);
  const [total_kilos_edit, setTotalKilosEdit] = useState(0);
  const [total_metros_edit, setTotalMetrosEdit] = useState(0);

  // const [inputFields, setInputFields] = useState([]);
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      unidad: 0,
      colores: "",
      // arrayColoresArticulo:[],
      cantidad: 0,
      precio: 0,
      descuento: 0,
      total: 0,
    },
  ]);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [status, setStatus] = useState('');

    //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50

  useEffect(() => {
    axios
    .get(`${URL_ORDENES_COMPRA}Tipo/Nacional`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allOrdenes = response.data;
    // Array para pagination
    let arrayTabla = allOrdenes
    .sort((a, b) => (a.idOrdenCompra > b.idOrdenCompra ? 1 : -1))
    .map((a) => {
      return {
        _id:a._id,
        numero: a.idOrdenCompra,
        activo: a.is_active,
        fecha: a.fecha,
        plazo: a.plazo,
        fecha_compromiso: a.fecha_compromiso,
        observaciones: a.observaciones,
        total_general: a.total_general,
        proveedores: a.proveedores[0].razon_social,
        idProveedores: a.proveedores[0]._id,
        total_kilos: a.total_kilos,
        total_metros: a.total_metros,
        status: a.status
      };
    }).filter(function (el) {
      return el != null;
    });

    let dataFinal = Object.values(arrayTabla);

    setOrdenesCompra(allOrdenes);
    setComments(dataFinal)
    //

      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_PROVEEDORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allProveedores = response.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_COLORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColores = response.data;
        setColores(allColores);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });

  }, []);

  const ListaOrdenesCompra = comments.sort((a, b) => (a.idOrdenCompra < b.idOrdenCompra ? 1 : -1))

  function PDFTabla() {
    const data = ListaOrdenesCompra.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
          (status == 0 ||
            status == a.status) &&
            (selectedProveedor == 0 ||
              selectedProveedor == a.idProveedores)
      ) {
        return [
          a.status,
          a.numero,
          a.fecha,
          a.proveedores,
          a.observaciones,
          a.total_kilos,
          a.total_metros,
          "$" + new Intl.NumberFormat("en-US").format(a.total_general),
         ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Ordenes de Compra`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Status","Numero","Fecha", "Proveedor", "Observaciones", "Kilos", "Metros", "Total"]],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Ordenes-Compra.pdf`);
  }

  function excel() {
    const dataExcel = ListaOrdenesCompra.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
          (status == 0 ||
            status == a.status) &&
            (selectedProveedor == 0 ||
              selectedProveedor == a.idProveedores)
      ) {
        return {
          Status: a.status,
          Numero:a.numero,
          Fecha: a.fecha,
          Proveedor: a.proveedores,
          Observaciones: a.observaciones,
          Kilos: a.total_kilos,
          Metros: a.total_metros,
          Total: a.total_general,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoOrdenesCompra";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoOrdenesCompra",
        sheetFilter: ["Status", "Numero", "Fecha", "Proveedor", "Observaciones", "Kilos", "Metros", "Total"],
        sheetHeader: ["Status", "Numero", "Fecha", "proveedor", "Observaciones", "Kilos", "Metros", "Total"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = ListaOrdenesCompra.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) 
          (status == 0 ||
            status == a.status) &&
            (selectedProveedor == 0 ||
              selectedProveedor == a.idProveedores)
      ) {
        return [
          a.status,
          a.numero,
          a.fecha,
          a.proveedor,
          a.observaciones,
          a.total_kilos,
          a.total_metros,
          "$" + new Intl.NumberFormat("en-US").format(a.total_general),
         ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Ordenes de Compra`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Status", "Numero", "Fecha", "Proveedor", "Observaciones", "Kilos", "Metros", "Total"]],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Ordenes Compra",
          email: mailTo,
          fileName: "ListadoOrdenesCompra.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Ordenes de Compra.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Enviado con Exito!",
          showConfirmButton: false,
          timer: 2000,
        });
        setMailTo("")
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfo(   
    fecha,
    plazo,
    fecha_compromiso,
    idProveedores,
    total_kilos,
    total_metros,
    total_general,
    observaciones,
    numero_orden,
    idEdit ) {
      setFecha(fecha);
      setPlazo(plazo)
      setFechaCompromiso(fecha_compromiso)
      setSelectedProveedorEdit(idProveedores)
      setTotalUnidaKilos(total_kilos)
      setTotalUnidaMetros(total_metros)
      setTotalGeneral(total_general)
      setObservaciones(observaciones)
      setNumeroOrden(numero_orden)
      setIdEdit(idEdit);
      toggleEdit();
  }

 async function PDFOC(   
  fecha,
  plazo,
  fecha_compromiso,
  idProveedores,
  proveedores,
  total_kilos,
  total_metros,
  total_general,
  observaciones,
  numero,
  idPDFOC ) {
     
     await axios
      .get(`${URL_ARTICULOS_OC}/ordenesCompra/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosOrden = response.data
        let arrayTabla = allArticulosOrden
        .map((a) => {
          return {
            id:a._id,
            articulos: a.articulos[0].codigo + " " + a.articulos[0].nombre,
            unidad: a.articulos[0].unidad,
            colores: a.colores[0].name,
            cantidad: a.cantidad,
            precio: a.precio,
            total: a.total,
            pendiente_recibir: a.pendiente_recibir
          };
        }).filter(function (el) {
          return el != null;
        });
    
        let dataFinal = Object.values(arrayTabla);  
    
        let mts = dataFinal.filter(e=>e.unidad == "Metros")
        let totalMts = mts.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);
        setTotalMetrosEdit(TM);
        let KG = dataFinal.filter(e=>e.unidad == "Kilos")
        let totalKG = KG.map((c) => parseFloat(c.cantidad));
        let TK = totalKG.reduce((t, total, index) => t + total, 0);
        setTotalKilosEdit(TK);
        let importes = dataFinal.map((c) => parseFloat(c.total));
        let TG = importes.reduce((t, total, index) => t + total, 0);
        setTotalGeneralEdit(TG);

        const data = dataFinal.map((a) => {
            return [
              a.articulos,
              a.unidad,
              a.colores,
              new Intl.NumberFormat("en-US").format(a.cantidad),
              "$" + new Intl.NumberFormat("en-US").format(a.precio),
              "$" + new Intl.NumberFormat("en-US").format(a.total),
             ]
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF("landscape");
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 230, 30, 45, 15);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 120, 15, 60, 25);
        doc.text(`Orden de Compra # ${numero}`, 20, 20);
        doc.setFontSize(12)
        doc.text(`Fecha ${fecha}`, 20, 25);
        doc.text(`Proveedor ${proveedores}`, 20, 30);
        doc.text(`Plazo ${plazo} días Compromiso ${fecha_compromiso}`, 20, 35);
        doc.text(`Metros ${new Intl.NumberFormat("en-US").format(total_metros)} / Kilos ${new Intl.NumberFormat("en-US").format(total_kilos)}`, 20, 40)
        doc.autoTable({
          head: [["Articulo","Unidad","Color", "Cantidad", "Precio", "Total"]],
          body: dataPDFLimpia,
          startY: 45,
          foot: [
            [
              "",
              "",
              "",
              "",
              "Total",
              "$" + new Intl.NumberFormat("en-US").format(total_general),
            ],
          ],
          showFoot: "lastPage",
        });
        doc.save(`OC-${numero}.pdf`);
      })
      .catch((err) => {
        console.log(err);
      });

     
  }


  function editOrdenes(event) {
    event.preventDefault();
    const URL_ORDENES_COMPRA_EDIT = `${process.env.REACT_APP_URL_ORDENES_COMPRA}/${idEdit}`;
        axios
          .all([
            axios.patch(
              URL_ORDENES_COMPRA_EDIT,
              {
                fecha,
                plazo,
                fecha_compromiso,
                proveedores: selectedProveedorEdit,
                total_kilos,
                total_metros,
                total_general,
                observaciones,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            ),
          ])
          .then(() => {
            axios.post(
              URL_LOGS,
              {
                tipo: "Editar Orden deCompra",
                detalle: `${numero_orden} ${fecha}`,
                user: user.id,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            );
           
            toggleEdit();
            Swal.fire("Good job!", "Actualizado con exito", "success");
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
  }


  const headers = [
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha", field: "fecha", sortable: false },
    { name: "Proveedor", field: "proveedores", sortable: false },
    { name: "Observaciones", field: "observaciones", sortable: true },
    { name: "Kilos", field: "total_kilos", sortable: true },
    { name: "Metros", field: "total_metros", sortable: true },
    { name: "Total", field: "total_general", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
    { name: "Editar", field: "editar", sortable: false },
];

  const commentsData = useMemo(() => {
    let computedComments = comments

    if (search) {
        computedComments = computedComments.filter(
            comment =>
                comment.observaciones.toLowerCase().includes(search.toLowerCase()) ||
                comment.proveedores.toLowerCase().includes(search.toLowerCase()) ||
                comment.total_kilos.toString().includes(search)||
                comment.total_metros.toString().includes(search)||
                comment.total_general.toString().includes(search)||
                comment.numero.toString().includes(search)
        );
    }
    if(selectedProveedor){
      computedComments = computedComments.filter((e)=>
      e.idProveedores.includes(selectedProveedor)
      ) 
    } 
    if(status){
      computedComments = computedComments.filter((e)=>
      e.status.includes(status)
      ) 
    }

    if(selectedFechaInicio){
      computedComments = computedComments.filter((e)=>
      e.fecha >= selectedFechaInicio
      ) 
    }
  
    if(selectedFechaFin){
      computedComments = computedComments.filter((e)=>
      e.fecha <= selectedFechaFin
      ) 
    }

    if(selectedFechaCompInicio){
      computedComments = computedComments.filter((e)=>
      e.fecha_compromiso >= selectedFechaCompInicio
      ) 
    }
  
    if(selectedFechaCompFin){
      computedComments = computedComments.filter((e)=>
      e.fecha_compromiso <= selectedFechaCompFin
      ) 
    }


    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field && sorting.field != "total_kilos" && sorting.field != "total_metros" && sorting.field != "total_general" && sorting.field != "numero") {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (sorting.field && sorting.order === "asc" && (sorting.field == "total_kilos" || sorting.field == "total_metros" || sorting.field == "total_general" || sorting.field == "numero")) {
      computedComments = computedComments.sort(
        (a, b) => parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (sorting.field && sorting.order === "desc" && (sorting.field == "total_kilos" || sorting.field == "total_metros" || sorting.field == "total_general" || sorting.field == "numero")) {
      computedComments = computedComments.sort(
        (a, b) => parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
}, [comments, currentPage, search, sorting, status, selectedFechaInicio, selectedFechaFin, selectedProveedor]);


function EditArticulos(idOC, numero_orden){
  setInputFields([
    {
      id: uuidv4(),
      articulos: "",
      unidad: 0,
      colores: "",
      // arrayColoresArticulo:[],
      cantidad: 0,
      precio: 0,
      descuento: 0,
      total: 0,
    },
  ])
  setIdEdit(idOC)
  setNumeroOrden(numero_orden)
  axios
  .get(`${URL_ARTICULOS_OC}/ordenesCompra/${idOC}`, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  })
  .then((response) => {
    let allArticulosOrden = response.data
    let arrayTabla = allArticulosOrden
    .map((a) => {
      return {
        id:a._id,
        articulos: a.articulos[0]._id,
        unidad: a.articulos[0].unidad,
        colores: a.colores[0]._id,
        // arrayColoresArticulo:a.articulos[0].colores,
        cantidad: a.cantidad,
        precio: a.precio,
        total: a.total,
        pendiente_recibir: a.pendiente_recibir,
        recibido: a.recibido,
      };
    }).filter(function (el) {
      return el != null;
    });

    let dataFinal = Object.values(arrayTabla);  
    setArticulosOrden(dataFinal);

    let mts = dataFinal.filter(e=>e.unidad == "Metros")
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetrosEdit(TM);
    let KG = dataFinal.filter(e=>e.unidad == "Kilos")
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilosEdit(TK);
    let importes = dataFinal.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneralEdit(TG);
  })
  .catch((err) => {
    console.log(err);
  });
  toggleEditArticulos()
}

const handleChangeInputExistentes = (id, event) => {
  const newArticulosOrden = articulosOrden.map((i) => {
    if (id === i.id) {
      i[event.target.name] = event.target.value;
      let totalParcial = i.cantidad * i.precio;
      i.total = totalParcial.toFixed(2);
    }
    return i;
  });
  setArticulosOrden(newArticulosOrden);
  ActualizaTotales()
};

const handleChangeInputExistentesCantidad = (id, event) => {
  const newArticulosOrden = articulosOrden.map((i) => {
    if (id === i.id) {
      let PR = parseFloat(i.pendiente_recibir) - parseFloat(i.cantidad) + parseFloat(event.target.value)
      i.pendiente_recibir = PR
      i[event.target.name] = event.target.value;
      let totalParcial = i.cantidad * i.precio;
      i.total = totalParcial.toFixed(2);
    }
    return i;
  });
  setArticulosOrden(newArticulosOrden);
  ActualizaTotales()
};


function BuscaArticuloExistentes(id, event){
  articulos.map((a)=>{
    if(a._id == event.target.value){
      let idArticulo = a._id
      let unidadArticulo = a.unidad
      // let coloresArticulo = a.colores
      handleChangeInputExistentesArticulo(id, idArticulo, unidadArticulo
        // , coloresArticulo
        )
    }
  })
}

const handleChangeInputExistentesArticulo = (id, idArticulo, unidadArticulo
  // , coloresArticulo
  ) => {
  const newArticulosOrden = articulosOrden.map((i) => {
    if (id === i.id) {
      i.articulos = idArticulo;
      i.unidad = unidadArticulo
      // i.arrayColoresArticulo = coloresArticulo
      let totalParcial = i.cantidad * i.precio;
      i.total = totalParcial.toFixed(2);
    }
    return i;
  });
  setArticulosOrden(newArticulosOrden);
  ActualizaTotales()
};

const handleAddFields = () => {
  const ultimo = inputFields[inputFields.length-1];
  setInputFields([
    ...inputFields,
    {
      id: uuidv4(),
      articulos: ultimo.articulos,
      unidad: ultimo.unidad,
      colores: ultimo.colores,
      // arrayColoresArticulo: ultimo.arrayColoresArticulo,
      cantidad: 0,
      precio: ultimo.precio,
      total: 0,
    },
  ]);
};

const handleRemoveFields = (id) => {
  const values = [...inputFields];
  values.splice(
    values.findIndex((value) => value.id === id),
    1
  );
  setInputFields(values);
  let mtsExistentes = articulosOrden.filter(e=>e.unidad == "Metros")
  let mtsNuevos = values.filter(e=>e.unidad == "Metros")
  let mts = mtsExistentes.concat(mtsNuevos)
  let totalMts = mts.map((c) => parseFloat(c.cantidad));
  let TM = totalMts.reduce((t, total, index) => t + total, 0);
  setTotalMetrosEdit(TM);

  let KGExistentes = articulosOrden.filter(e=>e.unidad == "Kilos")
  let KGNuevos = values.filter(e=>e.unidad == "Kilos")
  let KG = KGExistentes.concat(KGNuevos)
  let totalKG = KG.map((c) => parseFloat(c.cantidad));
  let TK = totalKG.reduce((t, total, index) => t + total, 0);
  setTotalKilosEdit(TK);
  
  let importesExistentes = articulosOrden.map((c) => parseFloat(c.total));
  let importesNuevos = values.map((c) => parseFloat(c.total));
  let importes = importesExistentes.concat(importesNuevos)
  let TG = importes.reduce((t, total, index) => t + total, 0);
  setTotalGeneralEdit(TG);

};

const handleChangeInput = (id, event) => {
  const newInputFields = inputFields.map((i) => {
    if (id === i.id) {
      i[event.target.name] = event.target.value;
      let totalParcial = i.cantidad * i.precio;
      i.total = totalParcial.toFixed(2);
    }
    return i;
  });
  setInputFields(newInputFields);
  ActualizaTotales()
};

function BuscaArticulo(id, event){
  articulos.map((a)=>{
    if(a._id == event.target.value){
      let idArticulo = a._id
      let unidadArticulo = a.unidad
      let coloresArticulo = a.colores
      handleChangeInputArticulo(id, idArticulo, unidadArticulo
        // , coloresArticulo
        )
    }
  })
}

const handleChangeInputArticulo = (id, idArticulo, unidadArticulo
  // , coloresArticulo
  ) => {
  const newInputFields = inputFields.map((i) => {
    if (id === i.id) {
      i.articulos = idArticulo;
      i.unidad = unidadArticulo
      // i.arrayColoresArticulo = coloresArticulo
      let totalParcial = i.cantidad * i.precio;
      i.total = totalParcial.toFixed(2);
    }
    return i;
  });
  setInputFields(newInputFields);
  ActualizaTotales()
};


function ActualizaTotales(){
      let mtsExistentes = articulosOrden.filter(e=>e.unidad == "Metros")
      let mtsNuevos = inputFields.filter(e=>e.unidad == "Metros")
      let mts = mtsExistentes.concat(mtsNuevos)
      let totalMts = mts.map((c) => parseFloat(c.cantidad));
      let TM = totalMts.reduce((t, total, index) => t + total, 0);
      setTotalMetrosEdit(TM);

      let KGExistentes = articulosOrden.filter(e=>e.unidad == "Kilos")
      let KGNuevos = inputFields.filter(e=>e.unidad == "Kilos")
      let KG = KGExistentes.concat(KGNuevos)
      let totalKG = KG.map((c) => parseFloat(c.cantidad));
      let TK = totalKG.reduce((t, total, index) => t + total, 0);
      setTotalKilosEdit(TK);
      
      let importesExistentes = articulosOrden.map((c) => parseFloat(c.total));
      let importesNuevos = inputFields.map((c) => parseFloat(c.total));
      let importes = importesExistentes.concat(importesNuevos)
      let TG = importes.reduce((t, total, index) => t + total, 0);
      setTotalGeneralEdit(TG);
}

const saveOrdenCompra = async (event) => {
  event.preventDefault();
  const URL_ORDENES_COMPRA_EDIT = `${process.env.REACT_APP_URL_ORDENES_COMPRA}/${idEdit}`;
  try {
    await axios
      .patch(
        URL_ORDENES_COMPRA_EDIT,
        {
          total_general:total_general_edit,
          total_kilos:total_kilos_edit,
          total_metros:total_metros_edit
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        inputFields.map((a) => {
          axios.post(
            URL_ARTICULOS_OC,
            {
              ordenesCompra: idEdit,
              articulos: a.articulos,
              colores: a.colores,
              cantidad: parseFloat(a.cantidad),
              recibido: 0,
              pendiente_recibir: parseFloat(a.cantidad),
              precio: parseFloat(a.precio),
              descuento: 0,
              total: parseFloat(a.total),
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          );
        })
        articulosOrden.map((a)=>{
          axios.patch(
            `${URL_ARTICULOS_OC}/${a.id}`,
            {
              articulos: a.articulos,
              colores: a.colores,
              cantidad: parseFloat(a.cantidad),
              precio: parseFloat(a.precio),
              descuento: 0,
              total: parseFloat(a.total),
              pendiente_recibir: parseFloat(a.pendiente_recibir)
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          );
        })
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Orden de Compra",
            detalle: `${numero_orden}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        Swal.fire("Good job!", "Creado con exito", `success`);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
      footer: `${error.response.data}`,
    });
    console.log(error);
  }
};

console.log("hola", articulosOrden)


  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_oc ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.oc_create ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/OrdenesCompraCreate"
                >
                  Nueva Orden
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nueva Orden
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuCompras"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Ordenes de Compra</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Ordenes de Compra</h3>
          <div className="row">
                        <div className="col-md-8">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                        <div className="col-md-4 d-flex flex-row-reverse">
                            <Search
                                onSearch={value => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                        </div>
                    </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label>Proveedores</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedProveedor}
                onChange={(e) => {
                  setSelectedProveedor(e.target.value)
                  setCurrentPage(1)
                }}
              >
                <option value="0">Selecciona</option>
                {proveedores
                  .sort((a, b) => (a.razon_social > b.razon_social ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.razon_social}</option>;
                  })}
              </Input>
            </Col>
 {/*
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Comp Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaCompInicio}
                required
                onChange={(e) => {
                  setSelectedFechaCompInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Comp Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaCompFin}
                required
                onChange={(e) => {
                  setSelectedFechaCompFin(e.target.value);
                }}
              />
            </Col> */}
          
            <Col md={2}>
              <Label>Status</Label>
              <Input
                bsSize="sm"
                type="select"
                value={status}
                required
                onChange={(e) => {
                  setStatus(e.target.value)
                  setCurrentPage(1)
                }}
              >
                <option value="">Selecciona</option>
                <option value="Abierto">Abierto</option>
                <option value="Cerrado">Cerrado</option>
              </Input>
            </Col>
          </Row>
            <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
                <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
            <tbody>
              {commentsData.map((a) => {
                {
                  return (
                    <tr>
                      <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.proveedores}</td>
                      <td>{a.observaciones}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.total_kilos)}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.total_metros)}</td>
                      <td>{"$" + new Intl.NumberFormat("en-US").format(a.total_general)} </td>
                      <td>
                        {user.oc_create ? (
                          <div>
                             <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) => PDFOC( 
                                a.fecha,
                                a.plazo,
                                a.fecha_compromiso,
                                a.idProveedores,
                                a.proveedores,
                                a.total_kilos,
                                a.total_metros,
                                a.total_general,
                                a.observaciones,
                                a.numero,
                                a._id )}
                            ><i class="far fa-file-pdf"></i>
                            </Button>
                           
                            <StatusMultiple
                              idStatus={a._id}
                              status={a.status}
                              URL_STATUS={process.env.REACT_APP_URL_ORDENES_COMPRA}
                              hijo={`${process.env.REACT_APP_URL_ARTICULOS_OC}/baja`}
                            />
                          </div>
                        ) : undefined}
                        </td>
                        <td>
                        { a.status == "Abierto" ?(
                          <div>
                                      <Button
                                      color="info"
                                      id="Editar"
                                      size="sm"
                                      onClick={(e) => jalaInfo(  
                                        a.fecha,
                                        a.plazo,
                                        a.fecha_compromiso,
                                        a.idProveedores,
                                        a.total_kilos,
                                        a.total_metros,
                                        a.total_general,
                                        a.observaciones,
                                        a.numero,
                                        a._id )}
                                      >
                                      <i class="far fa-edit"></i>
                                      </Button>
                                      <Button
                                      color="info"
                                      id="Editar"
                                      size="sm"
                                      onClick={(e) => EditArticulos(a._id, a.numero)}
                                      >
                                      <i class="fas fa-cart-plus"></i>
                                      </Button>
                                      </div>
                              ): (
                                <div>
                                  <Button color="info" id="Editar" size="sm" disabled>
                                    <i class="far fa-edit"></i>
                                  </Button>
                                  <Button
                                      color="info"
                                      id="Editar"
                                      size="sm" disabled                                      >
                                      <i class="fas fa-cart-plus"></i>
                                      </Button>
                                </div>
                              )}
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
        </div>
      ) : undefined}
      <br />


      

      <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>
            Editar Orden de Compra {numero_orden}
          </h4>
        </ModalHeader>
        <ModalBody>
        <Row>
              <Col md={3}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  onChange={(e) => {
                    setFecha(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Proveedor</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedProveedorEdit}
                  required
                  onChange={(e) => {
                    setSelectedProveedorEdit(e.target.value);
                  }}
                >
                  <option value="">Selecciona un Proveedor</option>
                  {proveedores
                    .sort((a, b) => (a.razon_social > b.razon_social ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.razon_social}</option>;
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label>Plazo</Label>
                <Input
                  type="text"
                  placeholder="Plazo"
                  value={plazo}
                  required
                  onChange={(e) => {
                    setPlazo(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Fecha Compromiso</Label>
                <Input
                  type="date"
                  placeholder="Fecha Compromiso"
                  value={fecha_compromiso}
                  required
                  onChange={(e) => {
                    setFechaCompromiso(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="textarea"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" 
          onClick={editOrdenes}
          >
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="xxl" isOpen={modalEditArticulos} toggle={toggleEditArticulos}>
            <ModalHeader toggle={toggleEditArticulos}>
              <h4>Editar Articulos OC {numero_orden}</h4>
              <h4>
              TOTALES {total_kilos_edit.toFixed(2)} kg. / {total_metros_edit.toFixed(2)} mts. / {"$" + new Intl.NumberFormat("en-US").format(total_general_edit.toFixed(2))}
              </h4>
              </ModalHeader>
            <ModalBody>
            <Row>
                <Col md={2}>
                  <Label className="mr-sm-2">Articulos</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Unidad</Label>
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Colores</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Cantidad</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Precio</Label>
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Total</Label>
                </Col>
              </Row>

              {articulosOrden.map((ao) => (
                <div key={ao.id}>
                  <Row>
                    <Col md={2}>
                      <Input
                        // bsSize="sm"
                        name="articulos"
                        type="select"
                        value={ao.articulos}
                        required
                        onChange={(event)=>{BuscaArticuloExistentes(ao.id, event)}}
                      >
                        <option value="0">Selecciona</option>
                        {articulos
                          .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                          .map((a) => {
                            return (
                              <option value={a._id}>
                                {a.codigo} / {a.nombre}
                              </option>
                            );
                          })}
                      </Input>
                    </Col>
                    <Col md={1}>
                        <Input
                        //  bsSize="sm"
                          name="unidad"
                          type="string"
                          value={ao.unidad}
                          disabled
                        >
                        </Input>
                      </Col>
                    <Col md={2}>
                      <Input
                        // bsSize="sm"
                        name="colores"
                        type="select"
                        value={ao.colores}
                        required
                        onChange={(event) =>
                          handleChangeInputExistentes(ao.id, event)
                        }
                      >
                        <option value="0">Selecciona</option>
                        {colores
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((a) => {
                            return <option value={a._id}>{a.name}</option>;
                          })}
                      </Input>
                    </Col>

                    <Col md={1}>
                      <Input
                        // bsSize="sm"
                        name="cantidad"
                        type="number"
                        placeholder="Cantidad"
                        value={ao.cantidad}
                        required
                        onChange={(event) =>
                          handleChangeInputExistentesCantidad(ao.id, event)
                        }
                      />
                    </Col>
                       {ao.recibido == 0 ? (
                         <Col md={1}>
                         <Input
                           // bsSize="sm"
                           name="precio"
                           type="number"
                           placeholder="Precio"
                           value={ao.precio}
                           required
                           onChange={(event) =>
                             handleChangeInputExistentes(ao.id, event)
                           }
                         />
                       </Col>
                       ) : (
                    <Col md={1}>
                      <Input
                        // bsSize="sm"
                        name="precio"
                        type="number"
                        placeholder="Precio"
                        value={ao.precio}
                        disabled
                      />
                    </Col>
                       )}
                    
                    <Col md={2}>
                      <Input
                        // bsSize="sm"
                        name="total"
                        type="number"
                        placeholder="Total"
                        value={ao.total}
                        disabled
                        onChange={(event) =>
                          handleChangeInputExistentes(ao.id, event)
                        }
                      />
                    </Col>
                  </Row>
                </div>
              ))}

              {/* Agregar mas articulos */}

              {inputFields.map((inputField) => (
                <div key={inputField.id}>
                  <Row>
                    <Col md={2}>
                      <Input
                        // bsSize="sm"
                        name="articulos"
                        type="select"
                        value={inputField.articulos}
                        required
                        onChange={(event)=>{BuscaArticulo(inputField.id, event)}}
                      >
                        <option value="0">Selecciona</option>
                        {articulos
                          .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                          .map((a) => {
                            return (
                              <option value={a._id}>
                                {a.codigo} / {a.nombre}
                              </option>
                            );
                          })}
                      </Input>
                    </Col>
                    <Col md={1}>
                        <Input
                        //  bsSize="sm"
                          name="unidad"
                          type="string"
                          value={inputField.unidad}
                          disabled
                        >
                        </Input>
                      </Col>
                    <Col md={2}>
                      <Input
                        // bsSize="sm"
                        name="colores"
                        type="select"
                        value={inputField.colores}
                        required
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      >
                        <option value="0">Selecciona</option>
                        {colores
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((a) => {
                            return <option value={a._id}>{a.name}</option>;
                          })}
                      </Input>
                    </Col>

                    <Col md={1}>
                      <Input
                        // bsSize="sm"
                        name="cantidad"
                        type="number"
                        placeholder="Cantidad"
                        value={inputField.cantidad}
                        required
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </Col>

                    <Col md={1}>
                      <Input
                        // bsSize="sm"
                        name="precio"
                        type="number"
                        placeholder="Precio"
                        value={inputField.precio}
                        required
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        // bsSize="sm"
                        name="total"
                        type="number"
                        placeholder="Total"
                        value={inputField.total}
                        disabled
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </Col>
                    <Col>
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        disabled={inputFields.length === 1}
                        onClick={() => handleRemoveFields(inputField.id)}
                        tabindex="-1"
                      >
                        <i class="fas fa-minus"></i>
                      </Button>
                      <Button 
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                      >
                      <i class="fas fa-plus"></i>
                      </Button>
                    </Col>
                  </Row>
                </div>
              ))}
              <Row>
              <Col md={6}>
              <h4 id="logoutBoton">
              TOTALES {total_kilos_edit.toFixed(2)} kg. / {total_metros_edit.toFixed(2)} mts. / {"$" + new Intl.NumberFormat("en-US").format(total_general_edit.toFixed(2))}
              </h4>
              </Col>
              </Row>
             
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" 
              onClick={saveOrdenCompra}
              >
                Enviar
              </Button>
            </ModalFooter>
          </Modal>

      {loader}
    </>
  );
}

export default ListadoOrdenesCompra;
