import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import qrcode from "qrcode-js";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";


function ListadoComplementosPago() {
  const { user } = useContext(AuthContext);
  const URL_COMP_PAGO = process.env.REACT_APP_URL_COMP_PAGO;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_TIMBRAR = process.env.REACT_APP_URL_TIMBRAR;

  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 90);
  
  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [activo, setActivo] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
    .get(`${URL_COMP_PAGO}Fechas/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allComplementos = response.data;
        // Array para pagination
        let arrayTabla = allComplementos
          .sort((a, b) => (a.idCompPago < b.idCompPago ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              is_active:a.is_active,
              fecha: a.fecha,
              idCompPago: a.idCompPago,
              folio: a.folio,
              serie: a.serie,
              clientes: a.clientes[0].nombre_comercial,
              idClientes: a.clientes[0]._id,
              vendedores: a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
              total: a.total,
              idFacturama: a.idFacturama,
              timbre: a.timbre,
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedFechaFin, selectedFechaInicio]);

  let totalImportePDF = 0;

  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        if(a.is_active == "Si"){
        totalImportePDF = totalImportePDF + a.total;
        return [
          a.fecha,
          a.serie + " - " + a.folio,
          a.clientes,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total),
        ]
      }else {
        return [
          a.fecha,
          a.serie + " - " + a.folio,
          a.clientes,
          "CANCELADA"
        ]
      }
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 10, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 160, 23, 25, 25);
    doc.text(`Listado de Complementos de Pago`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Fecha", "Folio", "Cliente", "Total"]],
      body: dataPDFLimpia,
      startY: 50,
      foot: [
        [
          "",
          "",
          "Totales",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalImportePDF),

        ]
      ]
    });
    doc.save(`ComplementosDePago.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        if(a.is_active == "Si"){
        return {
            Fecha: a.fecha,
            Folio: a.serie + " - " + a.folio,
            Cliente: a.clientes,
            Total: new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total),
        }
        }else{
          return {
            Fecha: a.fecha,
            Folio: a.serie + " - " + a.folio,
            Cliente: a.clientes,
            Total: 0,
       
          }
        }
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoComplementosDePago";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoComplementosDePago",
        sheetFilter: ["Fecha", "Folio", "Cliente", "Total"],
        sheetHeader: ["Fecha", "Folio", "Cliente", "Total"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        if(a.is_active == "Si"){
        totalImportePDF = totalImportePDF + a.total;
        return [
          a.fecha,
          a.serie + " - " + a.folio,
          a.clientes,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total),
        ]
      }else {
        return [
          a.fecha,
          a.serie + " - " + a.folio,
          a.clientes,
          "CANCELADA"
        ]
      }
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 10, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 160, 23, 25, 25);
    doc.text(`Listado de ComplementosDePago`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Fecha", "Folio", "Cliente", "Total"]],
      body: dataPDFLimpia,
      startY: 50,
      foot: [
        [
          "",
          "",
          "Totales",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalImportePDF),

        ]
      ]
    });
    var att = doc.output("arraybuffer");
    var base64File = encode.encode(att, "base64");

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado de Complementos de Pago",
          email: mailTo,
          fileName: "ListadoComplementosDePago.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Complementos de Pago.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }


  async function PDFOC(
    idFacturama,
    serie,
    folio
  ) {
    await axios
      .get(`${URL_TIMBRAR}ObtenerPDF/${idFacturama}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let facturaBase64 = response.data.respuesta.Content;
        
        const binaryData = atob(facturaBase64);
        
        const uint8Array = new Uint8Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
          uint8Array[i] = binaryData.charCodeAt(i);
        }
        
        const blob = new Blob([uint8Array], { type: "application/pdf" });
        
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `ComplementoDePago${serie}-${folio}.pdf`;
        
        a.click();
        
        URL.revokeObjectURL(url);
        
        
      })
      .catch((err) => {
        console.log(err);
      });
  }
  
  async function PDFOCXML(
    idFacturama,
    serie,
    folio
  ) {
    await axios
      .get(`${URL_TIMBRAR}ObtenerXML/${idFacturama}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
      const facturaXMLBase64 = response.data.respuesta.Content;

      const facturaBlob = b64toBlob(facturaXMLBase64, 'application/xml');

      const dataURL = URL.createObjectURL(facturaBlob);

      const a = document.createElement('a');
      a.href = dataURL;
      a.download = `ComplementoDePago${serie}-${folio}.xml`;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      function b64toBlob(base64, mimeType) {
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: mimeType });
      }

        
      })
      .catch((err) => {
        console.log(err);
      });
  }
  
  async function PDFOCXMLCANCELADA(
    id,
    serie,
    folio
  ) {
    await axios
      .get(`${URL_COMP_PAGO}/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let facturaXMLBase64 = response.data.respuestaFacturamaCancel[0].AcuseXmlBase64
        
      const facturaBlob = b64toBlob(facturaXMLBase64, 'application/xml');

      const dataURL = URL.createObjectURL(facturaBlob);

      const a = document.createElement('a');
      a.href = dataURL;
      a.download = `ComplementoDePago${serie}-${folio}.xml`;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      function b64toBlob(base64, mimeType) {
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: mimeType });
      }

        
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const headers = [
    { name: "Numero", field: "idCompPago", sortable: true },
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "Cliente", field: "clientes", sortable: true },
    { name: "Vendedor", field: "vendedores", sortable: true },
    { name: "Folio", field: "folio", sortable: true },
    { name: "Total", field: "total", sortable: true },
    { name: "Timbre", field: "timbre", sortable: true },
    { name: "Detalles", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.vendedores.toLowerCase().includes(search.toLowerCase()) ||
          comment.timbre.toLowerCase().includes(search.toLowerCase()) ||
          comment.folio.toString().includes(search) ||
          comment.total.toString().includes(search)
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "folio"  &&
      sorting.field != "total"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "folio" &&
      sorting.field == "total")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "folio"  &&
      sorting.field == "total")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCliente,
    selectedFechaInicio,
    selectedFechaFin,
  ]);

  function cancelarCompPago(id, idFacturama, clientes, total) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se cancelara el Complemento de Pago!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
    axios
      .patch(`${URL_TIMBRAR}CancelCompPago/${id}/${idFacturama}`,
      {
        clientes,
        total
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then(() => {
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
    }
  });
  }


  return (
    <>
      <Header />
      <br />
      <br />
      {user.compPagosFiscal ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
            <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/ComplementosPagoCreate"
                >
                  Nuevo Complemento
                </Button>
              <Button
                size="sm"
                href="/MenuFiscal"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado de Complementos de Pago</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Complementos de Pago</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label>Clientes</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedCliente}
                onChange={(e) => {
                  setSelectedCliente(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="0">Selecciona</option>
                {clientes
                  .sort((a, b) =>
                    a.nombre_comercial > b.nombre_comercial ? 1 : -1
                  )
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                if(a.is_active == "No"){
                  return (
                    <tr style={{ backgroundColor: "#ed8c8c"}}>
                      <td>{a.idCompPago}</td>
                      <td>{a.fecha}</td>
                      <td>{a.clientes}</td>
                      <td>{a.vendedores}</td>
                      <td>{a.serie} {a.folio}</td>
                      <td></td>
                      <td>CANCELADA</td>
                      <td>
                      <>
                            <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) =>
                                PDFOC(
                                  a.idFacturama,
                                  a.serie,
                                  a.folio
                                )
                              }
                            >
                              <i class="far fa-file-pdf"></i>
                            </Button>
                      <Button
                              size="sm"
                              className="btn"
                              color="info"
                              onClick={(e) =>
                                PDFOCXMLCANCELADA(
                                  a._id,
                                  a.serie,
                                  a.folio
                                )
                              }
                            >
                            <i class="fas fa-file-alt"></i>
                            </Button>
                      </>
                      </td>
                    </tr>
                  );
                }else{
                  // totalImporteTabla = totalImporteTabla + a.total_general;
                  // subTotalTabla = subTotalTabla + a.subTotal;
                  // totalIVATabla = totalIVATabla + a.iva;
                  return (
                    <tr>
                       <td>{a.idCompPago}</td>
                      <td>{a.fecha}</td>
                      <td>{a.clientes}</td>
                      <td>{a.vendedores}</td>
                      <td>{a.serie} {a.folio}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total)}</td>
                      <td>{a.timbre}</td>
                      <td>
                        {a.timbre == "Si" ? (
                          
                          <>
                            <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) =>
                                PDFOC(
                                  a.idFacturama,
                                  a.serie,
                                  a.folio
                                )
                              }
                            >
                              <i class="far fa-file-pdf"></i>
                            </Button>

                            <Button
                              size="sm"
                              className="btn"
                              color="info"
                              onClick={(e) =>
                                PDFOCXML(
                                  a.idFacturama,
                                  a.serie,
                                  a.folio
                                )
                              }
                            >
                              <i class="fas fa-file-alt"></i>
                            </Button>
                            <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) => cancelarCompPago(a._id, a.idFacturama, a.idClientes, a.total)}
                            >
                              <i class="fas fa-ban"></i>
                            </Button>
                          </>
                        ): undefined}
                      </td>
                    </tr>
                  );
                }

              })}
              {/* <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita" align="center">
                  TOTAL
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(subTotalTabla)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalIVATabla)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalImporteTabla)}
                </td>
                <td></td>
              </tr> */}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />
      {loader}
    </>
  );
}

export default ListadoComplementosPago;
